@import '../../styles/shared.scss';

.projects {
  display: flex;
  justify-content: space-around;
  flex-flow: column wrap;

  @include md {
    max-width: 700px;
    margin: 0 auto;
  }
}

.project {
  margin: 20px 40px 0;

  &:last-of-type {
    margin: 20px 40px;
  }

  &-name, &-dates {
    font-weight: 400;
    letter-spacing: 1.2px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-chips {
    margin: 10px 0 0;
    padding: 5px 0;
    display: flex;
    flex-flow: row wrap;
  }

  &-chip {
    margin: 0 5px 5px 0;
  }
}
