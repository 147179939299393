@import '../../styles/shared.scss';

.contact {
  margin: 20px 50px;
  
  h1 {
    font-weight: 400;
    margin-bottom: 10px;
  }

  @include md {
    max-width: 600px;
    margin: 30px auto 0;
  }
}

div.contact-form-field {
  padding: 10px 0;

  &:first-of-type {
    padding-top: 0;
  }
}