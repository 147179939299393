@import '../../styles/shared.scss';

.tags {
  display: flex;
  flex-flow: row wrap;
}

.tag {
  margin: 0 5px 5px 0;
  padding: 5px 10px;
  font-size: 11px;
  background-color: #eee;
  text-transform: uppercase;

  &:hover {
    background-color: $main-red;
    color: white;
    cursor: arrow;
  }
}
