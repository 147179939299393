
// Colors
$main-red: #501214;
$main-gold: #b1914e;

// Gutters
$gutter-xs: 4px;
$gutter-sm: 10px;
$gutter-md: 15px;
$gutter: 20px;
$gutter-lg: 30px;

// Screens
$screen-sm: 600px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

/* Screen mixins
  xs (phones, 600px and down)
  sm (portrait tablets and large phones, 600px and up)
  md (landscape tablets, 768px and up)
  lg (laptops/desktops, 992px and up)
  xl (large laptops and desktops, 1200px and up)
*/
@mixin xs {
  @media only screen and (max-width: $screen-sm) {@content;}
}

@mixin sm {
  @media only screen and (min-width: $screen-sm) {@content;}
}

@mixin md {
  @media (min-width: #{$screen-md}) {@content;}
}

@mixin gt-md {
  @media only screen and (min-width: $screen-md) {@content;}
}

@mixin lg {
  @media only screen and (min-width: $screen-lg) {@content;}
}

@mixin xl {
  @media only screen and (min-width: $screen-xl) {@content;}
}