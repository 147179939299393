@import './styles/shared.scss';

body {
  margin: 0;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

.home-header {
  display: flex;
  justify-content: space-around;
  flex-flow: column wrap;
  text-align: center;

  .home-header-section {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding: $gutter-sm 0;
  }

  .home-header-img {
    height: 170px;
    display: block;
    margin: 0 auto;

    @include sm {
      height: 230px;
    }

    @include md {
      height: 260px;
    }

    @include lg {
      height: 320px;
    }
  }

  .home-header-title {
    font-size: 30px;
    letter-spacing: 1.3px;
    font-weight: normal;

    @include sm {
      font-size: 35px;
    }

    @include md {
      font-size: 40px;
    }

    @include lg {
      font-size: 50px;
    }
  }

  .home-header-subtitle {
    font-size: 18px;

    @include md {
      font-size: 20px;
    }

    @include lg {
      font-size: 24px;
    }
  }

  .social-media {
    display: flex;
    justify-content: center;
    padding-top: $gutter-sm;

    .social-media-icon {
      height: 25px;
      padding: 0 10px;

      @include md {
        height: 25px;
      }

      @include lg {
        height: 33px;
        padding: 0 $gutter-sm * 2;
      }

      // @include xl {
      //   height: 45px;
      //   padding: 10px $gutter;
      // }
    }
  }

  @include sm {
    flex-flow: row wrap;
  }
}

.home-body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .home-body-section {
    width: 68%;
    display: flex;
    flex-flow: column wrap;
    align-self: center;
    text-align: center;
    padding: $gutter 0;

    &:last-of-type {
      padding-bottom: $gutter * 2;
    }

    @include lg {
      width: 50%;
    }
  }

  .home-body-header {
    padding: $gutter-sm * 2 0;

    @include md {
      font-size: 30px;
    }
  }

  .home-body-paragraph {
    font-size: 14px;
    padding-bottom: $gutter;

    @include md {
      font-size: 18px;
    }
  }
}

.home-btn {
  border: 2px solid $main-gold;
  border-radius: 30px;
  color: $main-red;
  width: 140px;
  height: 30px;
  font-size: 13px;
  background-color: transparent;

  &:hover {
    background-color: $main-gold;
    border: 1px solid $main-red;
    color: white;
    cursor: pointer;
  }

  &:active {
    color: white;
  }

  @include sm {
    width: 158px;
    height: 34px;
  }

  @include md {
    width: 168px;
    height: 37px;
    font-size: 18px;
  }
}
