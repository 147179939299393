@import '../../styles/shared.scss';

.header-menu {
    @include md {
        display: none;
    }
}

.menu-icon {
    color: white;
}

button.menu-icon-btn {
    @include md {
        display: none;
    }
}

a.app-bar-home-btn {
    color: white;
}

.tabs {
    display: none;

    @include md {
        display: inherit;
    }
}

.logo-container {
    display: flex;
}

.logo {
    height: 33px;

    @include md {
        height: 40px;
    }
}
