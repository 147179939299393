
.app-frame {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

footer {
  display: flex;
  justify-content: center;
  font-size: 15px;
  padding: 10px;
}
