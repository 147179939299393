@import '../../styles/shared.scss';

.cv-wrap {
  @include md {
    background-color: #eee;
  }
}

.cv {
  display: flex;
  flex-direction: column;
  margin: 15px 20px 0 20px;
  line-height: 1.5;
  font-size: 14px;
  font-family: Roboto,Helvetica,sans-serif;
  font-weight: 300;
  background-color: white;

  @include md {
    padding-top: 20px;
    max-width: 800px;
    margin: auto;
  }
}

.cv-header-img {
  height: 100px;
  width: auto;
}

.cv-section {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;

  &-header {
    font-size: 24px;
    margin: 20px 0 15px 0;
  }
  
  &-left {
    flex: 1;
    text-align: center;
  
    @include md {
      margin-right: 20px;
    }
  }

  &-right {
    flex: 3;
  }

  &-right.header {
    text-align: center;

    h1 {
      margin-top: 0;
    }

    > * {
      margin-top: 5px;
    }

    @include md {
      text-align: left;
      p {
        padding-right: 30px;
      }
    }
  }

  &.experience {
    margin: 10px 0;

    &:nth-of-type(3) {
      margin-top: 0;
    }

    &:nth-of-type(6) {
      margin-bottom: 0;
    }
  }

  @include md {
    flex-flow: row wrap;
  }
}

.contact-link {
  text-decoration: none;
  margin: 8px 5px 0 0;
  color: blue;
}

.company {
  background-color: #eee;
  letter-spacing: .5px;
  text-align: center;
  padding: 3px 0;
  font-size: 13px;
}

.dates {
  padding: 5px 0 10px 0;
  font-size: 13px;
}

.company, .dates {
  @include md {
    display: flex;
    justify-content: flex-end;

    p {
      margin-right: 5px;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 17px;
}

.description {
  padding: 5px 0;
}

.experience-list {
  list-style-position: outside;
  margin: 0;
  padding: 0 0 0 16px;
}

h1 {
  .name {
    font-weight: 500;
  }
}
